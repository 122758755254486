<template>
    <div class="myspisok">
        <div class="label">Температура</div>
        <div class="term">
            {{ omsh.temperature }}°
        </div>
        <div class="label">Влажность</div>
        <div class="term">
            {{ omsh.humidity }}%
        </div>
        <div class="label" style="font-size:12pt">Заряд батареи: {{ omsh.battery }}%</div>
        <div class="label" style="font-size:10pt">Статус: {{ omsh.state }}</div>
        <div class="small">
            <center>
                <line-chart :chart-data="datacollection" :options="{
                    responsive: true,
                    maintainAspectRatio: false
                }" class="line-chart"></line-chart>
            </center>
        </div>
    </div>
</template>

<script>
import LineChart from '../components/LineChart'
export default {
    components: {
        LineChart
    },
    data() {
        return {
            datacollection: null,
        }
    },
    mounted() {
        this.$store.commit('setActivePage', 1);
        if (this.$store.state.loggedIn) {
            this.$store.dispatch("yatermdataget");
            this.$store.dispatch('yatermhistdataget').then(this.fillData)
        }

    },
    computed: {
        omsh() {
            return this.$store.state.yaterm;
        }
    },
    methods: {
        fillData(data) {
            this.datacollection = {
                labels: data.map(el => (el.date)),
                datasets: [
                    {
                        label: 'Температура',
                        fill: false,
                        tension: 1,
                        borderColor: '#f87979',
                        data: data.map(el => (el.temperature))
                    }, {
                        label: 'Влажность',
                        fill: false,
                        tension: 1,
                        borderColor: '#79f879',
                        data: data.map(el => (el.humidity))
                    }
                ]
            }
        }
    }
}
</script>

<style>
.line-chart {
    width: 80%;
    height: 300px;

}
</style>
