import Axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        ekotel: {
            term: 0,
            on: true,
            timer: null,
            blocked: false,
            enabled: true
        },
        rozetka: {
            on: true,
            blocked: false
        },
        yaterm: {
            temperature: 0,
            battery: 0,
            humidity: 0,
            state: 'offline',
        },
        yatermhist: [],
        channel: null,
        koteldata: {},
        kotelsend: [],
        termdata: {},
        omshdata: [null],
        omshchart: {},
        timers: {
            kotel: null,
            term: null,
            omsh: null,
        },
        menu: {
            bottom: false,
        },
        activePage: 0,
        kotelcommandcodes: {
            p: 1,
            o: 2,
            v: 3,
            term: 4,
            rozgig: 7,
            aterm: 21,
            mode: 29
        },
        automatState: {
            0: 'Автомат остановлен',
            1: 'Автомат запускается',
            2: 'Автомат работает',
            5: 'Автомат остановлен по термостату',
            6: 'Автомат запускает розжиг',
            7: 'Автомат ждет пока отработает режим розжига',
            8: 'Автомат остановлен по превышению температуры',
            99: 'Автомат остановлен по отсутствию датчика температуры',
        },
        pusher: true,
        sms: false,
        loggedIn: true,
        loginCounter: 0,
    },
    mutations: {
        setLoginCounter(state) {
            state.loginCounter++;
        },
        setLoggedIn(state, val) {
            state.loggedIn = val;
        },
        setPusher(state, val) {
            state.pusher = val;
        },
        setSms(state, val) {
            state.sms = val;
        },
        setActivePage(state, page) {
            state.activePage = page;
            this.commit('menuset', false);
            this.commit('clearkotelsend');
        },
        addkotelsend(state, command) {
            if (state.kotelsend.indexOf(command) == -1) {
                state.kotelsend.push(command);
            }
        },
        clearkotelsend(state) {
            state.kotelsend.splice(0);
        },
        menuset(state, data) {
            state.menu.bottom = data;
        },
        koteldataedit(state, data) {
            this.commit('kotelstopupdate');
            this.commit('menuset', true);
            Vue.set(state.koteldata, data.type, data.val);
            this.commit('addkotelsend', data.type);
        },
        koteldataset(state, data) {
            if (data.btstatus == "ok")
                state.koteldata = data;
        },
        termdataset(state, data) {
            state.termdata = data;
            state.termdata.temp[4] -= 32;
            state.termdata.term[4] -= 32;
        },
        yatermhistdataset(state, data) {
            state.yatermhist = data;
        },
        omshdataset(state, data) {
            state.omshdata = data;
        },
        omshchartset(state, data) {
            state.omshchart = data;
        },
        omshdatasetPusher(state, data) {
            Vue.set(state.omshdata, +data.sn, data);
        },
        kotelstopupdate(state) {
            clearInterval(state.timers.kotel);
            state.timers.kotel = null;
        },
        termdataedit(state, data) {
            if (!state.pusher)
                this.commit('termstopupdate');
            this.commit('menuset', true);
            Vue.set(state.termdata[data.type], data.id, data.val);
        },
        termstopupdate(state) {
            clearInterval(state.timers.term);
            state.timers.term = null;
        },
        omshstopupdate(state) {
            clearInterval(state.timers.omsh);
            state.timers.omsh = null;
        },
    },
    actions: {
        commonSets(context) {
            context.commit('menuset', false);
            if (context.state.activePage == 0) {
                context.commit('clearkotelsend');
                context.dispatch('koteldataget');
            } else if (context.state.activePage == 1) {
                context.dispatch('omshdataget');
            } else {
                context.dispatch('termdataget');
            }
        },
        async koteldataget(context) {
            Axios
                .post('api/kotel.php', { getdata: '' })
                .then(r => {
                    context.commit('koteldataset', r.data);
                    if (!context.state.pusher)
                        if (context.state.timers.kotel == null)
                            context.state.timers.kotel = setInterval(() => {
                                if (context.state.timers.kotel != null)
                                    context.dispatch('koteldataget');
                            }, 5000);
                });
        },
        async koteldatasend(context, data = {
            vars: '',
            vals: ''
        }) {
            context.state.kotelsend.forEach((el) => {
                data.vals += context.state.koteldata[el] + ',';
                if (context.state.koteldata.kotelmode == 'auto' && el == 'term') el = 'aterm';
                data.vars += context.state.kotelcommandcodes[el] + ',';
            });
            Axios
                .post('api/kotel.php', {
                    vars: data.vars,
                    vals: data.vals,
                    appr: '',
                    client: 'vue.js',
                    pusher: (context.state.pusher) ? 0 : 1
                })
                .then(() => {

                });
        },

        async sendCommandToApp(context, data) {
            if (context.state.pusher) console.log('Команда "' + data.cmd + '" отправлена. Ждем сообщение от Pusher...')
            Axios
                .post('api/cmdsender.php', {
                    cmd: data.cmd,
                    msg: data.msg,
                    pusher: (context.state.pusher) ? 0 : 1,
                    sms: (context.state.sms) ? 1 : 0,
                    client: 'vue.js'
                });
        },

        async ekoteldataget({ state }) {
            if (!state.ekotel.blocked && state.ekotel.enabled)
                Axios.get(
                    `yandex/ekotel.php`
                ).then(response => {
                    if (!state.ekotel.blocked && state.ekotel.enabled) {
                        state.ekotel.term = response.data.term;
                        state.ekotel.on = response.data.on;
                    }
                }).catch(console.log);
        },

        async rozetkadataget({ state }) {
            if (!state.rozetka.blocked && state.ekotel.enabled)
                Axios.get(
                    `yandex/ekotel.php?key=true`
                ).then(response => {
                    if (!state.rozetka.blocked && state.ekotel.enabled) state.rozetka.on = response.data.key;
                }).catch(console.log);
        },

        async yatermdataget({ state }) {
            Axios.get(
                `yandex/ekotel.php?term=true`
            ).then(response => {
                state.yaterm.temperature = response.data.temperature
                state.yaterm.battery = response.data.battery
                state.yaterm.humidity = response.data.humidity
                state.yaterm.state = response.data.state
            }).catch(console.log);
        },

        async ekoteldatasend({ state }, cmd) {

            if (cmd == 'key_on') state.rozetka.on = true;
            if (cmd == 'key_off') state.rozetka.on = false;
            if (cmd == 'on') state.ekotel.on = true;
            if (cmd == 'off') state.ekotel.on = false;
            if (cmd == '+') state.ekotel.term++;
            if (cmd == '-') state.ekotel.term--;

            state.ekotel.blocked = true;
            state.rozetka.blocked = true;

            if (state.ekotel.term > 80) state.ekotel.term = 80;
            if (state.ekotel.term < 10) state.ekotel.term = 10;

            if (state.ekotel.timer) clearTimeout(state.ekotel.timer);
            state.ekotel.timer = setTimeout(() => {
                var data = {};
                if (cmd == 'key_on' || cmd == 'key_off') {
                    data.key = state.rozetka.on;
                } else if (cmd == 'on' || cmd == 'off') {
                    data.on = state.ekotel.on;
                } else {
                    data.term = state.ekotel.term;
                }
                Axios.post(
                    'yandex/ekotel.php',
                    data,
                ).then(() => {
                    state.ekotel.blocked = false;
                    state.rozetka.blocked = false;
                    state.ekotel.timer = null;
                }).catch(e => {
                    console.log(e);
                    state.ekotel.blocked = false;
                    state.rozetka.blocked = false;
                    state.ekotel.timer = null;
                });
            }, 1000);
        },

        async termdatasend(context, val = '') {

            if (val == '') {
                var key = 0;
                var delta = 0;
                for (var i = 0; i < 6; i++) {
                    if (i == 4) delta = 32;
                    else delta = 0;
                    val += ((context.state.termdata.term[i] + delta < 10) ? '0' : '') + (context.state.termdata.term[i] + delta);
                    key += Math.pow(2, 2 * i) * context.state.termdata.auto[i] + Math.pow(2, 2 * i + 1) * context.state.termdata.key[i];
                }
                var skey = String(key);
                for (; skey.length < 4; skey = "0" + skey);

                val += skey + ((context.state.termdata.term[6] < 10) ? '0' : '') + context.state.termdata.term[6] + String(context.state.termdata.auto[6] + 2 * context.state.termdata.key[6]);
            }

            Axios
                .post('api/term.php', {
                    val,
                    pusher: (context.state.pusher) ? 0 : 1,
                    sms: (context.state.sms) ? 1 : 0,
                });
        },

        async termdataget(context) {
            Axios
                .get('api/term.php')
                .then(r => {
                    context.commit('termdataset', r.data);
                    if (!context.state.pusher)
                        if (context.state.timers.term == null)
                            context.state.timers.term = setInterval(() => {
                                if (context.state.timers.term != null)
                                    context.dispatch('termdataget');
                            }, 5000);
                });
        },
        async omshdataget(context) {
            Axios
                .get('api/omsh.php')
                .then(r => {
                    context.commit('omshdataset', r.data);
                    if (!context.state.pusher)
                        if (context.state.timers.omsh == null)
                            context.state.timers.omsh = setInterval(() => {
                                if (context.state.timers.omsh != null)
                                    context.dispatch('omshdataget');
                            }, 5000);
                });
        },
        async yatermhistdataget(context) {
            return new Promise((resolve, reject) => {
                Axios
                    .get('api/yatermhist.php')
                    .then(r => {
                        context.commit('yatermhistdataset', r.data);
                        resolve(r.data);
                    }).catch(e => reject(e));
            });
        },
        async loggedIn(context) {
            Axios
                .post('api/login.php')
                .then(r => {
                    if (r.data == "OK") {
                        context.commit('setLoggedIn', true);
                        context.dispatch('commonSets');
                    } else {
                        context.commit('setLoggedIn', false);
                        context.commit('setPusher', false);
                    }
                    context.commit('setLoginCounter');
                });
        },
    }
});