import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Omsh from '../views/Omsh.vue'
import Term from '../views/Term.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Котёл',
        component: Home
    },
    {
        path: '/omsh',
        name: 'Омшаник',
        component: Omsh
    },
    {
        path: '/term',
        name: 'Термостат',
        component: Term
    }

]

const router = new VueRouter({
    routes
})

export default router