<template>
<div class="myspisok" v-if="termdata.term">
    <term-card v-for="(name ,id) in names" :key="id" :id="id" :name="name" />
    <div class="label">Улица</div>
    <div class="temp">{{termdata.out}}°</div>
    <div class="label" style="font-size:14pt">{{termdata.time}}</div>
    <hr>
    <center>
        <a class="contentbtn green" style="width: 100px; height: 50px;" v-if="!startHand" @click="startHandUpd();startHand = true;">Обновлять без котла</a>
        <div v-else>
            Данные температуры берутся напрямую из термостата, 
            приложение котла в этом режиме может не работать. 
            Для включения обычного режима работы через приложение котла, 
            обновите страницу.
        </div>
    </center>
</div>
</template>

<script>
export default {
    data() {
        return {
            names: ['Гостиная', 'Баня', 'Мансарда 2', 'Холл', 'Ограда', 'Мансарда 1', 'Бойлер'],
            startHand: false
        }
    },
    methods: {
        startHandUpd() {
            fetch("/api/term-cron.php");
            setInterval(() => {
                fetch("/api/term-cron.php");
            }, 10000);
        }
    },
    mounted() {
        this.$store.commit('setActivePage', 2);
        if (this.$store.state.loggedIn) {
            this.$store.dispatch('termdataget');
            if (this.$store.state.pusher) {
                this.$store.state.channel = this.$pusher.subscribe('lobastovy');
                this.$store.state.channel.bind('kotel', data => {
                    if (this.$store.state.menu.bottom == false)
                        this.$store.commit('termdataset', data.termostat);
                });
            }
        }
    },
    destroyed() {
        this.$store.commit('termstopupdate');
        this.$pusher.unsubscribe('lobastovy');
    },
    computed: {
        termdata() {
            return this.$store.state.termdata;
        }
    }
}
</script>
